<template>
  <sign-page
    title-text="执业证书管理"
    :request="request"
    :column-list="columnList"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    :form-parms-add="formParmsAdd"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    table-size="large"
    :tableActions="tableActions"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modalBg" width="1000px" v-if="modalBg">
      <fm-title title-text="变更记录"
        :title-menus="[{key: 'first', label: '新增首次注册'}, {key: 'goon', label: '新增延续注册'}, {key: 'change', label: '新增变更注册'}]"
        @clickTitleMenu="clickTitleMenu">
      </fm-title>
      <div class="zyzs-bjjl">
        <div v-for="item in updateLogList" :key="item.id">
          <div class="jl-item" v-if="item.type === 'change'">
            <fm-title title-text="变更注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
            <div class="log-info">
              <div>变更日期:</div>
              <span>{{item.changeDate}}</span>
            </div>
            <div class="log-info">
              <div>变更事项:</div>
              <span>{{item.changeContent}}</span>
            </div>
          </div>
          <div class="jl-item" v-if="item.type === 'goon'">
            <fm-title title-text="延续注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
            <div class="log-info">
              <div>注册日期:</div>
              <span>{{item.registDate}}</span>
            </div>
            <div class="log-info">
              <div>注册有效期至:</div>
              <span>{{item.registEndDate}}</span>
            </div>
          </div>
          <div class="jl-item" v-if="item.type === 'first'">
            <fm-title title-text="首次注册" @clickTitleMenu="clickTitleMenuLog($event, item)" :title-menus="[{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}]"></fm-title>
            <div class="log-info">
              <div>注册日期:</div>
              <span>{{item.registDate}}</span>
            </div>
            <div class="log-info">
              <div>注册有效期至:</div>
              <span>{{item.registEndDate}}</span>
            </div>
          </div>
        </div>
      </div>
    </fm-modal>
    <fm-form-dialog
      :form-title="logType === 'goon' ? '延续注册' : (logType === 'change' ? '变更注册' : '首次注册')"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="hisLogData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </sign-page>
</template>

<script>
import {
  workerCertificateRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  computed: {
    formParms () {
      let yx = [{
        type: 'datePicker',
        label: '注册日期',
        key: 'registDate'
      },
      {
        type: 'datePicker',
        label: '注册有效期至',
        key: 'registEndDate'
      }]
      let bg = [{
        type: 'datePicker',
        label: '注册日期',
        key: 'changeDate'
      },
      {
        type: 'input',
        label: '变更事项',
        key: 'changeContent'
      }]
      return this.logType === 'change' ? bg : yx
    },
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      let vm = this
      let data = [{
        key: 'changelog',
        label: '变更记录',
        dealFun: (pageVm, parm2) => {
          vm.pageVm = pageVm
          vm.choose = JSON.parse(JSON.stringify(parm2))
          vm.updateLogList = vm.choose.updateLogList || []
          vm.modalBg = true
        }
      },
      {
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['practicing_certificate_type'],
          label: '执业证书类型',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'input',
          label: '签发人',
          key: 'issueUser'
        },
        {
          type: 'datePicker',
          label: '发证日期',
          key: 'getDate',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.workerList,
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '执业地点',
          key: 'practiceAddress'
        },
        {
          type: 'input',
          label: '执业类别',
          key: 'practiceType'
        },
        {
          type: 'input',
          label: '执业范围',
          key: 'practiceRange'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          if (item.key === 'workerId') {
            newItem.fmDisabled = true
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '执业证书类型',
          field: 'mType',
          sort: true,
          filters: this.$store.getters.selectDatas['practicing_certificate_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '证书编码',
          field: 'code',
          sort: true
        },
        {
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '发证机关',
          field: 'issueOrg',
          sort: true
        },
        {
          title: '签发人',
          field: 'issueUser',
          sort: true,
          width: 100
        },
        {
          title: '发证日期',
          field: 'getDate',
          sort: true,
          width: 200,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
          }
        },
        {
          title: '有效期开始时间',
          field: 'hireStartDate',
          sort: true,
          width: 200,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
          }
        },
        {
          title: '有效期结束时间',
          field: 'hireEndDate',
          sort: true,
          width: 200,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '-'
          }
        },
        {
          title: '身份证号',
          field: 'idNo',
          sort: true
        },
        {
          title: '执业地点',
          field: 'practiceAddress',
          sort: true
        },
        {
          title: '执业类别',
          field: 'practiceType',
          sort: true
        },
        {
          title: '执业范围',
          field: 'practiceRange',
          sort: true
        // },
        // {
        //   title: '扫描件状态',
        //   field: 'imgStatusText',
        //   sort: true
        // },
        // {
        //   title: '最近更新扫描件时间',
        //   field: 'imgUploadTime',
        //   sort: true,
        //   dataType: Date
        }]
        return data
      }
    }
  },
  methods: {
    async clickTitleMenuLog (key, data) {
      this.logType = data.type
      if (key === 'edit') {
        this.hisLogData = Object.assign({}, data)
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该' + (this.logType === 'change' ? '变更注册' : (this.logType === 'goon' ? '延续注册' : '首次注册')) + '吗?'})
        if (!result) {
          return
        }
        this.updateLogList = this.updateLogList.filter(v => v.id !== data.id)
        let parm2 = this.onChange(JSON.stringify(this.updateLogList), this.choose)
        await request.update(this.choose.id, parm2)
        this.openDialog = false
        this.pageVm.loadData()
      }
    },
    clickTitleMenu (key) {
      this.hisLogData = null
      this.logType = key
      this.openDialog = true
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {type: 'doctor_pra'}
    },
    async formSubmit (parm, resolve) {
      for (let key in parm) {
        if (parm[key] instanceof Date) {
          parm[key] = dateOperating.computeDay({days: 0, date: parm[key], format: 'yy-mm-dd'})
        }
      }
      parm.type = this.logType
      let newData = []
      if (parm.id) {
        this.updateLogList.forEach((v) => {
          if (v.id === parm.id) {
            newData.push(parm)
          } else {
            newData.push(v)
          }
        })
      } else {
        newData = this.updateLogList.map(v => v)
        parm.id = new Date().getTime()
        newData.push(parm)
      }
      newData.sort((a, b) => {
        let datea = new Date(a.registDate || a.changeDate)
        let dateb = new Date(b.registDate || b.changeDate)
        return dateb - datea
      })
      this.updateLogList = newData
      let parm2 = this.onChange(JSON.stringify(newData), this.choose)
      await request.update(this.choose.id, parm2)
      this.openDialog = false
      this.pageVm.loadData()
      resolve()
    },
    onChange (value, chooseData) {
      let data = {
        updateLog: value
      }
      let datas = value ? JSON.parse(value) : []
      datas = datas.filter(v => ['goon', 'first'].includes(v.type))
      if (datas.length === 0) {
        data['hireStartDate'] = chooseData['getDate']
        data['hireEndDate'] = null
      } else {
        data['hireStartDate'] = datas[0].registDate
        data['hireEndDate'] = datas[0].registEndDate
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd'})
        }
      }
      console.log('onChange', data)
      return data
    },
    async loadData () {
      let parm = {
        type: 'doctor_pra'
      }
      let data = await request.get(parm)
      data.forEach(v => {
        v.updateLogList = v.updateLog ? JSON.parse(v.updateLog) : []
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })
      return data 
    }
  },
  data () {
    return {
      logType: '',
      hisLogData: null,
      updateLogList: [],
      choose: null,
      openDialog: false,
      modalBg: false,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.zyzs-bjjl {
  min-height: 10rem;
}
.jl-item {
  border: 1px solid #dcdee2;
  border-radius: 3px;
  padding: 8px 16px;
  margin: 1rem 0;
}
.log-info {
  display: flex;
  min-height: 2rem;
  align-items: center;
  div {
    padding-right: 1rem;
  }
}
.log-info-t {
  color: #515a6e;
}
</style>